


.how-to-create-design {
    box-sizing: border-box;

    font-family: 'Arial', sans-serif;
    line-height: 1.6;
    color: #333;
    background-color: #333333;
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
}

.how-to-create-design__title {
    color: #f6aa0f;
    text-align: center;
    margin-bottom: 20px;
    font-size: 45px; /* Moved here for specificity */
}

.how-to-create-design__subtitle {
    color: #6200ea;
    margin: 10px 0;
    font-size: 30px;
}

.cta-button {
    background-color: #ff4081;
    color: #fff;
    padding: 10px 20px;
    text-decoration: none;
    border-radius: 5px;
    font-weight: bold;
    display: inline-block;
    margin: 5px 0;
}

.cta-button:hover {
    background-color: #ff1e56;
}

.how-to-create-design__section {
    padding: 15px;
    margin: 20px 0;
    background-color: #fff;
    border-radius: 5px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    transition: box-shadow 0.3s;
}

.how-to-create-design__section:hover {
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
}

.how-to-create-design__image {
    max-width: 70%;
    height: auto;
    display: block;
    margin: 20px auto;
    cursor: pointer; /* Change cursor to indicate image is clickable */
}

.how-to-create-design__list {
    margin-left: 20px;
}

.how-to-create-design__footer {
    background-color: #6200ea;
    color: #fff;
    text-align: center;
    padding: 20px 0;
    margin-top: 20px;
}

.how-to-create-design__footer-link {
    color: #ff4081;
    text-decoration: none;
    margin: 0 10px;
    font-weight: bold;
}

.how-to-create-design__social-media {
    margin: 20px 0;
}

.how-to-create-design__faq-item {
    margin: 10px 0;
}

.how-to-create-design__faq-item-title {
    margin-bottom: 5px;
    color: #f39c12;
}

/* Modal styles */
.how-to-create-design_modal {
    display: none;
    position: fixed;
    z-index: 1000;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgba(0, 0, 0, 0.8);
}

.how-to-create-design_modal-content {
    margin: 15% auto;
    display: block;
    max-width: 90%;
    max-height: 80%;
}

.how-to-create-design_close {
    position: absolute;
    top: 20px;
    right: 35px;
    color: white;
    font-size: 40px;
    font-weight: bold;
    transition: 0.3s;
}

.how-to-create-design_close:hover,
.how-to-create-design_close:focus {
    color: #ff4081;
    text-decoration: none;
    cursor: pointer;
}

@media (max-width: 768px) {
    .how-to-create-design__title {
        font-size: 30px;
    }

    .how-to-create-design__subtitle {
        margin-bottom: 3px;
        margin-top: -10px;
    }

    .cta-button {
        margin-top: -10px;
        margin-bottom: -10px;
        margin-left: 25%;
    }

    .how-to-create-design__image {
        max-width: 100%;
        height: auto;
        display: block;
        margin: 20px auto;
        cursor: pointer; /* Change cursor to indicate image is clickable */
    }
}
