.google-login-page {
    min-height: 90vh;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    background-image: url('https://ai-tattoo-website-images.s3.us-east-1.amazonaws.com/newhomeBackground01_small_desktop.jpg');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}

.card {
    border: none;
    background-color: rgba(255, 255, 255, 0.85); /* Slightly transparent background */
    padding: 2rem; /* Padding for better spacing */
    border-radius: 10px; /* Rounded corners for a nice look */
    width: 950px;
    max-width: 90%; /* Ensure the card is responsive on smaller screens */
    margin: 0 auto; /* Center the card horizontally */
}

.card-title {
    color: #343a40;
    font-size: 2.5rem;
    font-weight: 700;
    text-align: center; /* Center the title */
}

.extra-text {
    font-size: 1rem; /* Small text */
    color: #495057; /* Neutral text color */
    text-align: center; /* Center the text */
    margin-top: 1rem; /* Space between the button and this text */
}

.extra-text2 {
    font-size: 0.8rem; /* Small text */
    color: #495057; /* Neutral text color */
    text-align: center; /* Center the text */
    margin-top: 0.75rem; /* Space between the button and this text */
}

.extra-text a {
    color: #007bff; /* Highlight the link in blue */
    text-decoration: underline; /* Underline the link to indicate it's clickable */
    font-weight: 500; /* Slightly bolder for better visibility */
}

.extra-text a:hover {
    color: #0056b3; /* Darker blue when hovered */
    text-decoration: none; /* Remove underline on hover */
}

.card-text {
    color: #6c757d;
    font-size: 1.25rem;
    text-align: center; /* Center the text */
}


/* Mobile adjustments */
@media (max-width: 768px) {
    .card-title {
        font-size: 1.8rem;
    }

    .card {
        width: 83%;
    }

    .google-login-page {
        background-image: url('https://ai-tattoo-website-images.s3.us-east-1.amazonaws.com/newhomeBackground02_small_mobile.jpg');
    }
}
