.popup-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.popup-content {
    background: #000; /* Set background to black */
    padding: 20px;
    border-radius: 8px;
    width: 90%;
    max-width: 800px;
    max-height: 90%;
    position: relative;
    overflow-y: auto;
    scrollbar-width: thin; /* Makes scrollbar thinner */
    scrollbar-color: #ccc #000; /* Custom scrollbar colors */
}

/* Custom scrollbar styling for WebKit browsers */
.popup-content::-webkit-scrollbar {
    width: 8px;
}

.popup-content::-webkit-scrollbar-track {
    background: #000; /* Background of the scrollbar track */
}

.popup-content::-webkit-scrollbar-thumb {
    background-color: #ccc; /* Color of the scrollbar handle */
    border-radius: 4px;
}

.close-button {
    background: red; /* Red background for the close button */
    color: white; /* White color for the X */
    border: none;
    font-size: 24px;
    cursor: pointer;
    z-index: 1001;
    padding: 10px;
    border-radius: 8px;
    position: sticky; /* Absolute position to place it at the top-right corner */
    top: 10px;
    left: 90%;
}

.image-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr); /* 3 images per row on desktop */
    gap: 10px;
    margin-top: 10px; /* Add margin to push images below the close button */
}


.popup-image {
    width: 45%;
    height: auto;
    border-radius: 8px;
    object-fit: cover; /* Ensures the images are resized to fit within their grid cells */
}

/* Responsive */
@media (max-width: 767px) {
    .image-grid {
        grid-template-columns: repeat(2, 1fr); /* 2 images per row on mobile */
    }

    .popup-image {
        width: 100%;
        height: auto;
        border-radius: 8px;
        object-fit: cover; /* Ensures the images are resized to fit within their grid cells */
    }
}
