.out-of-credit-paywall{
    margin-top: -3px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%; /* Adjust to make it centered */
    position: relative;
    background-image: url('https://ai-tattoo-s3-bucket.s3.us-east-1.amazonaws.com/newhomeBackground01_small_desktop.jpg');
    background-position: center;
    overflow: hidden;
}

.out-of-credit-paywall::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.2); /* Black overlay with 50% opacity */
    z-index: 1; /* Ensure it’s above the background image but below the content */
}

.out-of-credit-paywall-paywall{
    width: 20%;
    z-index: 2;
}

/* Mobile view */
@media (max-width: 600px) {

    .out-of-credit-paywall-paywall {
        width: 100%;
        margin-left: 45px;
        margin-top: 20px;
    }

    .out-of-credit-paywall {

        background-image: url('https://ai-tattoo-website-images.s3.us-east-1.amazonaws.com/newhomeBackground01_small_desktop.jpg');
        width: 100%; /* Adjust to make it centered */
    }
}
