/* General Styles */
body {
    font-family: 'Helvetica Neue', Arial, sans-serif;
    background-color: #000000;
    margin: 0;
    padding: 0;
}

.paywall {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 5px;
    max-width: 500px;
    background: #fff;
    border-radius: 20px;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
    z-index: 2;
}

h1 {
    font-size: 20px;
    margin-bottom: 2px;
    color: #333;
}

/* Plan Switch Container */
.plan-switch {
    display: flex;
    justify-content: center;
    margin-bottom: 5px;
    width: 80%;
    max-width: 350px;
    background-color: #f0f0f0;
    border-radius: 30px;
    overflow: hidden;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    padding: 5px;
}

/* Switch Button */
.switch-button {
    flex: 1;
    text-align: center;
    padding: 7px 20px;
    cursor: pointer;
    background-color: #ddd;
    border: none;
    border-radius: 25px;
    margin: 0 2px;
    transition: background-color 0.3s ease, color 0.3s ease;
    font-size: 16px;
}

/* Selected Button */
.switch-button.selected {
    background-color: #0070f3;
    color: white;
}

/* Hover Effect */
.switch-button:hover {
    background-color: #005bb5;
    color: white;
}

/* Plans Container */
.plans {
    display: flex;
    justify-content: center;
    width: 100%;
    margin-bottom: 5px;
}

/* Plan Box */
.plan {
    flex: 1;
    padding: 10px;
    margin: 0 15px;
    border: 2px solid #ccc;
    border-radius: 20px;
    background: #f9f9f9;
    text-align: center;
    cursor: pointer;
    transition: all 0.3s ease;
}

.plan.selected {
    border-color: #0070f3;
    background: #e0f3ff;
    box-shadow: 0 0 15px rgba(0, 112, 243, 0.2);
}

.plan h2 {
    font-size: 22px;
    margin-bottom: 5px;
    color: #0070f3;
}

.plan ul {
    list-style: none;
    padding: 0;
    color: #333;
    text-align: left;
    margin-top: 5px;
}

.plan ul li {
    margin-bottom: 4.5px;
    padding-left: 10px;
    position: relative;
}


/* Billing Options */
.billing-options {
    display: flex;
    justify-content: space-around;
    width: 100%;
    margin-top: 13px;
}

.billing-option {
    flex: 1;
    text-align: center;
    padding: 13px;
    margin: 0 10px;
    border: 2px solid #ccc;
    border-radius: 15px;
    background: #f5f5f5;
    cursor: pointer;
    transition: all 0.3s ease;
}

.billing-option.selected {
    border-color: #0070f3;
    background: #e0f3ff;
}

.billing-option p {
    margin: -4px 0;
    font-size: 17px;
    color: #333;
}

.strikethrough {
    text-decoration: line-through;
    color: red;
    margin-right: 5px; /* Add space between MSRP and final price */
}

/* Total Price */
.total-price {
    margin-top: -10px;
    text-align: center;
    color: #000000;
    padding: 7px;
    border-radius: 10px;

}

.total-price h3 {
    font-size: 20px;
    margin: 0;
    font-weight: normal;
}

/* Button */
button {
    padding: 12px 35px;
    font-size: 18px;
    color: #fff;
    background-color: #0070f3;
    border: none;
    border-radius: 10px;
    cursor: pointer;
    transition: background 0.3s ease;
    margin-top: 20px;
}

button:hover {
    background-color: #005bb5;
}

/* Badge */
.badge {
    display: inline-block;
    padding: 5px 10px;
    font-size: 14px;
    color: #fff;
    background-color: #0070f3; /* AB Test: Consider this green color #38aa2e*/
    border-radius: 8px;
    margin-top: 5px;
}

/* Renew Automatically Text */
.renew-automaticly-text {
    color: #666;
    font-size: 14px;
    margin-top: -15px;
    margin-bottom: 4px;
}

/* Responsive Design */
@media (max-width: 768px) {
    .plans {
        flex-direction: column;
        align-items: center;
    }

    .plan {
        width: 95%;
        margin: -5px 0;
    }

    .plan-switch {
        width: 95%;
    }

}

.first-paywall-benefit{
    margin-top: -10px;
}
