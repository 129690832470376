/* Accessibility tools visibility on desktop */
.accessibility-tools-desktop {
    display: block;
}

/* Hide the mobile accessibility tools by default */
.accessibility-tools-mobile {
    display: none;
}

.mobile-out-nav {
    display: block;
}

/* Display mobile accessibility tools only when the menu is open */
@media (max-width: 991px) {
    .accessibility-tools-desktop {
        display: none;
    }

    .mobile-out-nav {
        margin-right: -40px;
    }

    .accessibility-tools-mobile {
        display: block;
        margin-right: 0px; /* Adjust spacing as needed */
        margin-top: -12px;
    }

    .right-side-nav {
        flex-direction: column; /* Stack elements vertically */
        align-items: flex-start; /* Align items to the left */
    }

    .right-side-nav > * {
        margin-bottom: 10px; /* Add some space between elements */
    }

    .right-side-nav > *:last-child {
        margin-bottom: 0; /* Remove margin from the last element */
    }


    .mobile-right-side-nav .custom-link {
        font-size: 20px;
    }

    .mobile-right-side-nav {
        flex-direction: column; /* Stack elements vertically */
        font-size: 20px;
        display: flex;
        align-items: center;
    }

    .nav-link {
        margin-top: 20px;
    }

}


/* General Styling */
.right-side-nav {
    display: flex;
    align-items: center;
    margin-left: auto;
}

.custom-link {
    margin-right: 25px;
    margin-left: 30px;
    font-size: 18px;
    color: #a8afb6;
}

.logo-icon {
    width: 2.5rem;
    height: 2.5rem;
    margin-right: 0.5rem;
}

.logo-text {
    display: flex;
    flex-direction: column;
    justify-content: center;
    line-height: 1;
}

.main-title {
    font-size: 1.5rem;
}

.subtitle {
    margin-top: 3px;
    font-size: 14px;
    color: #fafafa;
}

.start-now-button {
    background-color: #8048e6;
    color: white;
}

body {
    padding-top: 70px;
}
