/* General accessibility tools styling */
.accessibility-tools {
    position: absolute;
    right: 10px;
    top: 1px;
    z-index: 2000;
}

/* Icon styling */
.accessibility-icon {
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 40%;
    padding: 7px;
    cursor: pointer;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

/* Menu styling */
.accessibility-menu {
    position: absolute;
    right: 0;
    top: 50px;
    background-color: #222;
    color: white;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    padding: 2px;
    width: 130px;
}

.menu-item {
    display: flex;
    align-items: center;
    margin-bottom: 2px;
}

.menu-item button {
    background: none;
    border: none;
    color: white;
    cursor: pointer;
    margin-left: 5px;
    font-size: 13px;
}

.menu-item button:hover {
    text-decoration: underline;
}

/* High contrast mode */
.high-contrast {
    background-color: #000 !important;
    color: #fff !important;
    filter: contrast(2) !important;
}

/* Font size classes */
.font-size-small {
    font-size: 0.8rem; /* Smaller font size */
}

.font-size-medium {
    font-size: 1rem; /* Default font size */
}

.font-size-large {
    font-size: 1.2rem; /* Larger font size */
}

/* Media query to disable forced location on mobile */
@media (max-width: 991px) {
    .accessibility-tools {
        position: static;
        right: auto;
        top: auto;
    }

    /* Menu styling */
    .accessibility-menu {
        position: absolute;
        right: 0;
        top: 220px;
        background-color: #222;
        color: white;
        border-radius: 8px;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
        padding: 3px;
        width: 150px;
    }

    /* Icon styling */
    .accessibility-icon {
        background-color: #444f7c;
        color: white;
        border: none;
        border-radius: 22%;
        padding: 8px;
        cursor: pointer;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    }


}

/* AccessibilityTools.css */
.color-blind-mode {
    /* Example color adjustments for color-blind mode */
    filter: grayscale(80%);
}

/* High contrast mode (if already not defined) */
.high-contrast {
    background-color: #000;
    color: #fff;
}
