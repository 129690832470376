/* Help Center Page Styles */
.helpCenter {
    margin-top: 70px;
    padding: 20px;
    background-color: #000;
    color: #fff;
    font-family: Arial, sans-serif;
    text-align: center;
}

.banner {
    width: 100%;
    height: 200px;
    background: url('https://ai-tattoo-website-images.s3.us-east-1.amazonaws.com/help_center_comp2.jpg') no-repeat center center;
    /*background: url('https://ai-tattoo-website-images.s3.us-east-1.amazonaws.com/help_center_comp1.jpg') no-repeat center center;*/
    background-size: max(450px);
    margin-bottom: 40px;
}

.helpCenter h1 {
    font-size: 36px;
    margin-bottom: 20px;
    color: #f39c12;
}

.intro {
    font-size: 24px;
    margin-bottom: 40px;
    width: 40%;
    margin: 0 auto;
    text-align: center;
}

.faq-section {
    max-width: 800px;
    margin: 0 auto;
}

.faq-item {
    background-color: #333;
    padding: 20px;
    margin-bottom: 20px;
    border-radius: 10px;
    text-align: left;
    cursor: pointer;
    position: relative;
}

.faq-item h3 {
    font-size: 22px;
    color: #f39c12;
    margin-bottom: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.faq-item p {
    font-size: 18px;
    line-height: 1.6;
    margin-top: 10px;
}

.faq-item.open p {
    display: block;
}

.arrow {
    transition: transform 0.3s ease;
}

.arrow.open {
    transform: rotate(180deg);
}

.contact-section {
    max-width: 800px;
    margin: 40px auto 0;
    text-align: center;
}

.contact-section p {
    font-size: 18px;
}

.contact-section a {
    color: #f39c12;
    text-decoration: none;
}

.contact-section a:hover {
    text-decoration: underline;
}

@media (max-width: 768px) {
    .helpCenter h1 {
        font-size: 28px;
    }

    .intro {
        font-size: 20px;
        width: 80%;
    }

    .faq-item h3 {
        font-size: 20px;
    }

    .faq-item p {
        font-size: 16px;
    }

    .contact-section p {
        font-size: 16px;
    }
}
