.shortFAQ-container {
    margin-top: 70px;
    padding: 20px;
    background-color: #000;
    color: #fff;
    font-family: Arial, sans-serif;
    text-align: center;
}

.shortFAQ-header {
    font-size: 28px;
    margin-bottom: 20px;
    text-align: center;
    color: #f39c12;
}

.shortFAQ-intro {
    font-size: 24px;
    margin-bottom: 40px;
    width: 40%;
    margin: 0 auto;
    text-align: center;
}

.shortFAQ-section {
    max-width: 800px;
    margin: 20px auto;
}

.shortFAQ-item {
    background-color: #333;
    border-radius: 10px;
    padding: 20px;
    margin-bottom: 20px;
    cursor: pointer;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    position: relative;
}

.shortFAQ-item-title {
    font-size: 22px;
    color: #f39c12;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.shortFAQ-item-content {
    font-size: 18px;
    color: #fff;
    line-height: 1.6;
    margin-top: 10px;
    display: none;
}

.shortFAQ-item.open .shortFAQ-item-content {
    display: block;
}

.shortFAQ-arrow {
    transition: transform 0.3s ease;
}

.shortFAQ-arrow.open {
    transform: rotate(180deg);
}

@media (max-width: 768px) {
    .shortFAQ-header {
        font-size: 24px;
    }

    .shortFAQ-intro {
        font-size: 20px;
        width: 80%;
    }

    .shortFAQ-item-title {
        font-size: 20px;
    }

    .shortFAQ-item-content {
        font-size: 16px;
    }
}
