.terms {
    margin-top: 70px;
    padding: 20px;
    background-color: #000;
    color: #fff;
    font-family: Arial, sans-serif;
    text-align: center;
}

.terms h1 {
    font-size: 36px;
    margin-bottom: 40px;
    color: #f39c12;
}

.terms-section {
    margin-bottom: 40px;
    text-align: left;
}

.terms-section h4 {
    font-size: 18px;
    margin-bottom: 20px;
    color: #ffffff;
}

.terms-section p {
    font-size: 18px;
    margin-bottom: 20px;
    line-height: 1.6;
}


@media (max-width: 768px) {
    .terms-section {
        text-align: center;
    }

    .terms-section h2 {
        font-size: 24px;
    }

    .terms-section p {
        font-size: 16px;
    }
}
