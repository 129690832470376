/* About Us Page Styles */
.aboutUs {
    margin-top: 70px;
    padding: 20px;
    background-color: #000;
    color: #fff;
    font-family: Arial, sans-serif;
    text-align: center;
}

.aboutUs h1 {
    font-size: 36px;
    margin-bottom: 40px;
    color: #f39c12;
}

.aboutUs-section {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: flex-start;
    margin-bottom: 40px;
    text-align: left;
}

.aboutUs-text-content, .aboutUs-image-content {
    padding: 20px;
}

.aboutUs-text-content {
    flex: 0 0 70%;
    max-width: 70%;
}

.aboutUs-image-content {
    flex: 0 0 30%;
    max-width: 30%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.about-us-image {
    max-width: 100%;
    height: auto;
    border-radius: 10px;
}

.aboutUs-section h2 {
    font-size: 28px;
    margin-bottom: 20px;
    color: #f39c12;
}

.aboutUs-section p {
    font-size: 18px;
    margin-bottom: 20px;
    line-height: 1.6;
}

@media (max-width: 768px) {
    .aboutUs-section {
        flex-direction: column;
        text-align: center;
    }

    .aboutUs-text-content, .aboutUs-image-content {
        flex: 1 0 100%;
        max-width: 100%;
        padding: 10px;
    }

    .aboutUs-text-content {
        order: 2;
    }

    .aboutUs-image-content {
        order: 1;
    }

    .aboutUs-section h2 {
        font-size: 24px;
    }

    .aboutUs-section p {
        font-size: 16px;
    }
}
