.home-container {
    width: 100%;
}

.section {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 70vh;
    width: 100%;
    position: relative;
}

.first-section {
    margin-top: 35px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 82vh;
    width: 100%;
    position: relative;
    background-image: url('https://ai-tattoo-website-images.s3.us-east-1.amazonaws.com/newhomeBackground01_small_desktop.jpg');
    background-position: center;
    overflow: hidden;
}

.paywall-section {
    min-height: 92vh; /* Minimum height of 92vh, but allows it to grow */
    height: auto; /* Allow it to grow based on content */
    overflow: visible; /* Ensure any extra content is shown */
}

.main-section-benefits{
    margin-top: 45px;
    font-size: 18px;
}

.tattoogpt-demo-section{
    background-image: url('https://ai-tattoo-website-images.s3.us-east-1.amazonaws.com/newhomeBackground01_small_desktop.jpg');
    background-size: cover;
    background-position: center;
    height: 78vh;
}

.first-section::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.55); /* Black overlay with 50% opacity */
    z-index: 1; /* Ensure it’s above the background image but below the content */
}

.get-started-button {
    background-color: #8048e6;
}

.section-image-container {
    position: relative;
    z-index: 2;
}

.section-content {
    flex: 1;
    padding: 20px;
    color: white;
    position: relative; /* Ensure content is above the overlay */
    z-index: 2;
}

.press-a-container {
    position: fixed;
    top: 10px;
    right: 10px;
    background: white;
    border: 2px solid black;
    padding: 7px;
    border-radius: 5px;
    z-index: 1000;
    margin-top: 120px;
}

.press-a-text {
    font-size: 16px;
    font-weight: bold;
}

.square-a {
    display: inline-block;
    padding: 1.5px 7px;
    border: 2px solid black;
    background-color: black;
    color: white;
    font-weight: bold;
    font-size: 15px;
}


.main-section-title {
    font-size: 60px;
    color: white;
}

.section-image {
    width: 550px;
    height: 550px;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    margin-left: 100px;
    margin-top: 75px;
    margin-bottom: 50px;
}

/* Mobile adjustments */
@media (max-width: 768px) {
    .section {
        flex-direction: column;
        height: auto;
        padding: 20px 0;
    }

    .section-image {
        width: 100%;
        height: auto;
        margin-left: 0;
        margin-top: 0px;
        margin-bottom: 20px;
    }

    .section-content {
        padding: 20px;
    }

    .main-section-title {
        font-size: 36px;
        color: white;
        margin-top: 30px;
        margin-bottom: 12px;
    }

    .main-section-benefits{
        font-size: 17px;
        margin-top: 7px;
    }

    .press-a-container {
        display: none;
    }
}

.how-it-works-section {
    background-color: #282A35;
    padding: 20px;
    color: white;
}

.how-it-works-title {
    text-align: center;
    font-size: 2em;
    margin-bottom: 20px;
    margin-top: 60px;
}

.steps-container {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
}

.step {
    flex: 1;
    text-align: center;
}

.step-content {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.icon {
    font-size: 28px;
    color: #36ca31; /* gold color for icons */
    margin-right: 15px;
}

.comment-icon {
    font-size: 28px;
    color: #9373c1; /* gold color for icons */
    margin-right: 15px;
}

.arrow {
    font-size: 24px;
    margin: 0 10px;
}

/* Mobile adjustments */
@media (max-width: 768px) {
    .first-section {
        background-image: url('https://ai-tattoo-website-images.s3.us-east-1.amazonaws.com/newhomeBackground02_small_mobile.jpg');
    }

    .tattoogpt-demo-section{
        margin-top: -10px;
        margin-bottom: -10px;
        background-image: url('https://ai-tattoo-website-images.s3.us-east-1.amazonaws.com/newhomeBackground02_small_mobile.jpg');
    }

    .steps-container {
        flex-direction: column;
        align-items: center;
    }

    .step {
        margin-bottom: 20px;
    }

    .arrow {
        display: none;
    }

    .how-it-works-image-container {
        display: flex;
        justify-content: center;
        margin-top: 10px;
        width: 100%;
    }

    .how-it-works-image {
        width: 20%;
    }
}

.how-it-works-image-container {
    display: flex;
    justify-content: center;
    margin-top: 0px;
    width: 100%;
}

.how-it-works-image {
    width: 55%;
    margin-bottom: 0px;
}

.customers-reviews-title {
    margin-top: 80px;
}

/* Mobile adjustments */
@media (max-width: 768px) {
    .how-it-works-image {
        width: 110%;
    }
}

.how-it-works-title {
    text-align: center;
    font-size: 2em;
    margin-bottom: 20px;
    margin-top: 0px;
}

.why-choose-us-section-content {
    padding: 20px;
    color: white;
}

.our-customers-reviews-section-content {
    padding: 20px;
    color: white;
}

.customers-reviews-title {
    margin-top: 0px;
}

.review-card {
    background-color: #383C4A;
    padding: 20px;
    margin-bottom: 20px;
    border-radius: 8px;
}

.stars {
    display: flex;
    align-items: center;
}

.star-icon {
    color: gold;
    margin-right: 5px;
    font-size: 25px;
}

.reviewer-name {
    margin-left: 10px;
}

/* Mobile adjustments */
@media (max-width: 768px) {
    .review-card {
        padding: 15px;
    }

    .star-icon {
        font-size: 16px;
    }

    .reviewer-name {
        font-size: 14px;
    }
}

/* General styles for Section component */
.section-children {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.section-image-container {
    display: flex;
    justify-content: center;
    margin-top: -10px;
}

.section-image {
    width: 420px;
    height: 420px;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    margin-right: 90px;
    margin-top: 140px;
    margin-bottom: 95px;
    border-radius: 55px;
}

.image-text {
    text-align: center;
    margin-top: 10px;
    color: white;
}

/* Mobile adjustments */
@media (max-width: 768px) {
    .section {
        flex-direction: column;
        height: auto;
        padding: 20px 0;
    }

    .first-section {
        margin-top: 20px;
        flex-direction: column;
        height: auto;
        padding: 20px 0;
    }

    .get-started-button {
        margin-top: 2px;
        margin-left: 6px;
        padding: 13px;
        font-size: 18px;
    }

    .section-image {
        width: 65%;
        height: auto;
        margin-right: 0;
        margin-top: -5px;
        margin-bottom: 0px;
    }

    .section-content {
        padding: 20px;
    }

    .image-text {
        font-size: 14px;
    }
}

.articles-section-content {
    color: white;
    text-align: center;
    padding: 20px;
}

.articles-title {
    font-size: 2.5rem;
    margin-bottom: 20px;
}

.article-card {
    background-color: #333232;
    margin: 20px auto;
    padding: 15px;
    border-radius: 10px;
    max-width: 600px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.article-card:hover {
    background-color: #5a5858;
    cursor: pointer;

}

.article-logo {
    height: 40px;
    margin-bottom: 10px;
}

.article-title {
    font-size: 1.2em; /* Balanced size */
    font-weight: 600;
    margin: 15px 0;
    color: #f7b731; /* Bright golden-yellow for emphasis */
    text-transform: capitalize;
    text-align: center;
    letter-spacing: 0.8px; /* Slightly more spaced */
    position: relative;
    transition: color 0.3s ease; /* Smooth color transition */
}

.article-title:hover {
    color: #f6f1f1; /* Slightly brighter color on hover */
}

.article-title::after {
    content: '';
    display: block;
    width: 50%;
    height: 2px;
    background: linear-gradient(to right, #ff9f43, #e3d7d7);
    margin: 8px auto 0;
    border-radius: 1px; /* Rounded edges for the line */
}

.article-description {
    font-size: 1em;
    margin-bottom: 10px;
    color: #d1d1d1; /* Soft gray for a subtle contrast */
}

.read-more-link {
    color: #e0e2e4; /* Bright and modern blue */
    text-decoration: none;
    transition: color 0.3s ease; /* Smooth color transition */
}

.read-more-link:hover {
    color: #eaefec; /* Vibrant green on hover */
}


.read-more-link:hover {
    text-decoration: underline;
}

@media (max-width: 768px) {
    .article-card {
        padding: 10px;
    }

    .articles-title {
        font-size: 2.5em;
    }

    .article-title {
        font-size: 1.2em;
    }

    .article-description {
        font-size: 0.9em;
    }

    .article-logo {
        height: 40px;
    }
}


/* Style for the SEO Links Section */
.seo-links {
    background-color: #070606;
    padding: 20px;
    margin-top: 40px;
    border-radius: 8px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

.seo-links h2 {
    font-size: 24px;
    font-weight: bold;
    color: #f39c12;
    margin-bottom: 15px;
    text-align: center;
}

.seo-links ul {
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.seo-links ul li {
    margin: 10px 0;
}

.seo-links ul li a {
    text-decoration: none;
    color: #5a75d5;
    font-size: 18px;
    transition: color 0.3s ease;
}

.seo-links ul li a:hover {
    color: #f39c12;
    text-decoration: underline;
}

@media (min-width: 768px) {
    .seo-links ul {
        flex-direction: row;
        justify-content: center;
    }

    .seo-links ul li {
        margin: 0 15px;
    }
}


/* Prevent horizontal scrolling */
body {
    overflow-x: hidden;
}
