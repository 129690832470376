.feedback-page {
    margin-top: 80px;
    min-height: 80vh;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;
    background-image: url('https://ai-tattoo-website-images.s3.us-east-1.amazonaws.com/newhomeBackground01_small_desktop.jpg');
}

.feedback-page .card {
    background-color: rgba(0, 0, 0, 0.85);
    padding: 3rem;
    border-radius: 10px;
    max-width: 750px;
    width: 100%;
}

.feedback-page .card-title {
    color: #ffffff;
    font-size: 2.2rem;
    font-weight: 700;
}

.feedback-page .card-text {
    color: #d3d3d3;
    font-size: 18px;
}

.feedback-page .form-control {
    background-color: #222;
    color: #ffffff;
    border: 1px solid #555;
}

.feedback-page .btn-primary {
    background-color: #007bff;
    border: none;
}


@media (max-width: 768px) {
    .feedback-page {
        background-image: url('https://ai-tattoo-website-images.s3.us-east-1.amazonaws.com/newhomeBackground02_small_mobile.jpg');
    }
}
