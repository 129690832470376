.view-tattoo {
    margin-top: 70px;
    padding: 20px;
    background-color: #000;
    color: #fff;
    font-family: Arial, sans-serif;
    text-align: center;
}

.view-tattoo h1 {
    font-size: 36px;
    margin-bottom: 5px;
    color: #f39c12;
}

.view-tattoo .description {
    font-size: 18px;
    line-height: 1.6;
    margin-bottom: -20px;
}

.view-tattoo .image-container {
    margin-bottom: 20px;
}

.view-tattoo .tattoo-image {
    max-width: 30%;
    height: auto;
    border-radius: 10px;
}

.view-tattoo .generate-button {
    background-color: #f39c12;
    color: #fff;
    font-size: 18px;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    margin-bottom: 5px;
}

.view-tattoo .generate-your-button {
    background-color: #b02ed6;
}

.view-tattoo .generate-your-button:hover {
    background-color: #c853ef;
}

.view-tattoo .share-buttons {
    display: flex;
    justify-content: center;
    gap: 0px;
}

.view-tattoo .share-buttons .btn {
    font-size: 16px;
    display: flex;
    align-items: center;
}

/* Mobile view */
@media (max-width: 600px) {
    .view-tattoo h1 {
        font-size: 28px;
    }

    .view-tattoo .description {
        font-size: 16px;
    }

    .view-tattoo .share-buttons {
        flex-direction: column;
    }

    .view-tattoo .share-buttons .btn {
        width: 100%;
        justify-content: center;
    }

    .view-tattoo .tattoo-image {
        max-width: 100%;
        height: auto;
        border-radius: 10px;
    }
}
