.banner-container {
    position: sticky;
    top: 70px; /* Adjust this value based on the height of your navbar */
    width: 100%;
    background-color: #ffd83b;
    display: flex;
    align-items: center;
    cursor: pointer;
    z-index: 999; /* Ensure it's on top of other elements but below the navbar */
    padding: 10px;
    box-shadow: 0px 4px 2px -2px gray;
}

.banner-image {
    width: 25px;
    height: 25px;
    margin-right: 15px;
    border-radius: 50%; /* Makes the image circular */
}

.banner-container {
    position: sticky;
    top: 70px; /* Adjust this value based on the height of your navbar */
    width: 100%;
    font-weight: bold;
    background-color: #8826b6;
    /*background-color: #482e60;*/
    color: #e1dddd;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    z-index: 999; /* Ensure it's on top of other elements but below the navbar */
    padding: 7px;
    box-shadow: 0px 4px 2px -2px gray;
}

.banner-container:hover {
    background-color: #8826b6;
}
