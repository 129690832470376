.apply-coupon-container {
    position: relative;
    min-height: 100vh;
    background-color: #f5f5f5;
}

.apply-coupon-background {
    background-image: url('https://ai-tattoo-website-images.s3.us-east-1.amazonaws.com/newhomeBackground01_small_desktop.jpg');
    background-size: cover;
    background-position: center;
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

.apply-coupon-card {
    background-color: rgba(255, 255, 255, 0.9);
    border-radius: 10px;
    padding: 40px;
    width: 90%;
    max-width: 400px;
    text-align: center;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.apply-coupon-title {
    font-size: 2em;
    color: #333;
    margin-bottom: 20px;
}

.apply-coupon-text {
    font-size: 1em;
    color: #555;
    margin-bottom: 20px;
}

.coupon-input {
    width: 100%;
    padding: 10px;
    font-size: 1em;
    margin-bottom: 15px;
    border: 1px solid #ccc;
    border-radius: 5px;
}

.apply-coupon-button {
    width: 100%;
    padding: 10px;
    background-color: #4CAF50;
    color: white;
    font-size: 1.1em;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    margin-bottom: 15px;
}

.apply-coupon-button:disabled {
    background-color: #ccc;
    cursor: not-allowed;
}

.apply-coupon-message {
    font-size: 1em;
    margin-bottom: 15px;
}

.apply-coupon-message.success {
    color: green;
}

.apply-coupon-message.error {
    color: red;
}

.generate-tattoo-button {
    width: 100%;
    padding: 10px;
    background-color: #FF5722;
    color: white;
    font-size: 1.1em;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

@media (max-width: 768px) {
    .apply-coupon-card {
        padding: 20px;
    }

    .apply-coupon-title {
        font-size: 1.5em;
    }
}
