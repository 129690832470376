
.text-center{
    margin-top: 90px;
}

.thank-you-container{
    text-align: left;
}

.Thankyou-green-check-img{

}

.summary-title{
    font-weight: bold;
}