.gallery-container {
    text-align: center;
    padding: 20px;
    min-height: 75vh;
    background: linear-gradient(to right, #000000, #716c6b);
}

.gallery-title {
    color: white;
    margin-bottom: 20px;
}

h2 {
    font-size: 2.5rem;
}

.gallery-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
    gap: 10px;
    padding: 10px;
}

.image-container {
    position: relative;
    cursor: pointer;
}

.gallery-image {
    width: 100%;
    height: auto;
    border-radius: 5px;
    transition: transform 0.3s;
}

.gallery-image:hover {
    transform: scale(1.05);
}

.no-images {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.no-images p {
    font-size: 1.2rem;
    margin-bottom: 10px;
}

.no-images .btn {
    padding: 10px 20px;
    font-size: 1rem;
}

.loading-container {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    gap: 10px;
    padding: 10px;
}

.modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.8);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
}

.modal-image {
    max-width: 90%;
    max-height: 90%;
    border-radius: 10px;
}

.modal:active {
    cursor: pointer;
}

.load-more-button {
    margin-top: 20px;
    padding: 10px 20px;
    font-size: 1rem;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
}

.load-more-button:hover {
    background-color: #0056b3;
}
