/* General Styles */
.recovery-page {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
    background-color: #1c1c1c; /* Darker for contrast */
    text-align: center;
    margin-top: 60px;
    padding: 0;
}

.content-container {
    color: #ffffff;
    max-width: 800px;
    width: 100%;
    padding: 20px;
    border-radius: 15px;
    background-color: #292929;
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.7);
}

.title {
    font-size: 2.8rem;
    color: #ff9f1c; /* Eye-catching yellow-orange */
    font-weight: bold;
}

.recovery-image {
    width: 70%;
    max-width: 700px;
    border-radius: 10px;
    margin: 0.5rem 0;
}

.offer-text {
    font-size: 1.8rem;
    color: #8ecae6; /* Cool light blue */
    margin: 0.5rem 0;
    font-weight: bold;
}

.highlight {
    font-size: 1.8rem;
    color: #ff4d6d; /* Vibrant red for urgency */
    margin: 0.5rem 0;
}

.price {
    font-weight: bold;
    font-size: 2rem;
}

.one-time-payment {
    font-size: 1.2rem;
    color: #f5f5f5;
    margin-bottom: 1.5rem;
    font-style: italic;
}

.description {
    font-size: 1.2rem;
    color: #d9d9d9;
    margin: 1.5rem 0;
}

.payment-method {
    margin: 1.5rem 0;
    font-size: 1rem;
    color: #000000;
}

.offer-expiry {
    font-size: 1.3rem;
    color: #f0a500; /* Bright gold */
    margin-top: 0.5rem;
    font-weight: bold;
}

/* Login and Checkout Button Styling */
.recovery-login {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 1.5rem;
}

.checkout-buttons-recovery-container {
    background-color: #ffffff;
    padding: 0px;
    border-radius: 10px;

}

/* Responsive Design */
@media (max-width: 768px) {
    .title {
        font-size: 2rem;
    }

    .recovery-image {
        width: 100%;
        margin-bottom: 10px;
    }

    .highlight {
        font-size: 1.5rem;
    }

    .content-container {
        padding: 15px;
    }

    .description {
        font-size: 1rem;
    }

}
