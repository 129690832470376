.my-account-page {
    margin-top: 80px;
    min-height: 80vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #000000; /* Black background */
    padding: 20px;
}

.my-account-page .card {
    border: none;
    background-color: rgba(0, 0, 0, 0.85); /* Slightly transparent background */
    padding: 3rem; /* Padding for better spacing */
    border-radius: 10px; /* Rounded corners for a nice look */
    width: 100%; /* Set width to 100% for mobile */
    max-width: 750px; /* Maintain max width for larger screens */
}

.my-account-page .card-title {
    color: #ffffff; /* White text */
    font-size: 2.5rem;
    font-weight: 700;
    text-align: left; /* Left align the title */
}

.my-account-page .card-text {
    color: #d3d3d3; /* Light gray text */
    font-size: 19px;
    text-align: left; /* Left align the text */
}

.my-account-page .img-fluid {
    border-radius: 50%; /* Rounded image */
    width: 100%; /* Ensure image takes up full width on mobile */
}

.my-account-page .side-image {
    width: 100%; /* Ensure side image takes up full width on mobile */
    height: auto; /* Maintain aspect ratio */
    border-radius: 10px;
    margin-left: 20px;
}

.account-actions-button {
    margin: 10px 5px 0 5px; /* Adjusted margins for better alignment */
    width: 100%; /* Full width buttons on mobile */
    max-width: 200px; /* Maintain button width on larger screens */
}

@media (max-width: 768px) {
    .my-account-page .card-title {
        font-size: 2rem; /* Slightly smaller title on smaller screens */
    }

    .my-account-page .card-text {
        font-size: 18px; /* Slightly smaller text on smaller screens */
    }

    .my-account-page .img-fluid {
        width: 100%;
        max-width: 200px; /* Restrict max width on smaller screens */
        height: auto; /* Maintain aspect ratio */
    }

    .my-account-page .side-image {
        width: 100%;
        height: auto;
    }

    .account-actions-button {
        margin: 10px 0; /* Adjusted margins for better alignment */
        width: 100%; /* Full width buttons on mobile */
    }
}
