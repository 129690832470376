
.coming-soon-page {
    min-height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background-image: url('https://ai-tattoo-website-images.s3.us-east-1.amazonaws.com/newhomeBackground01_small_desktop.jpg');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}

.coming-soon-page .card {
    border: none;
    background-color: rgba(255, 255, 255, 0.85); /* Slightly transparent background */
    padding: 3rem; /* Padding for better spacing */
    border-radius: 10px; /* Rounded corners for a nice look */
    width: 100%; /* Ensure the card takes full width of the column */
}

.coming-soon-page .card-title {
    color: #343a40;
    font-size: 2.5rem;
    font-weight: 700;
    text-align: center; /* Center the title */
}

.coming-soon-page .card-text {
    color: #6c757d;
    font-size: 1.25rem;
    text-align: center; /* Center the text */
}

.coming-soon-page img {
    max-width: 100%;
    height: auto;
    display: block;
    margin: 0 auto;
}
