body {
    font-family: 'Helvetica Neue', Arial, sans-serif;
    background-color: #000000;
    margin: 0;
    padding: 0;
}

.paywall {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 7px;
    width: 100%;
    max-width: 420px;
    background: #ffffff;
    border-radius: 10px;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
}

.paywall-second-title {
    font-size: 1rem;
    color: #555;
    text-align: center;
    margin-top: 5px;
}

h1 {
    font-size: 22px;
    margin-bottom: 2px;
    color: #333333;
}

/* Plan Switch Container */
.plan-switch {
    display: flex;
    justify-content: center;
    margin-bottom: 5px;
    margin-top: -9px;
    width: 100%;
    max-width: 350px;
    background-color: #f0f0f0;
    border-radius: 30px;
    padding: 5px;
}

/* Switch Button */
.switch-button {
    flex: 1;
    text-align: center;
    padding: 7px 20px;
    cursor: pointer;
    background-color: #dddddd;
    border: none;
    border-radius: 25px;
    margin: 0 5px;
    transition: background-color 0.3s ease, color 0.3s ease;
    font-size: 16px;
    font-weight: bold;
    color: #333333;
}

.switch-button.selected {
    background-color: #0070f3;
    color: #ffffff;
}

.switch-button:hover {
    background-color: #005bb5;
    color: #ffffff;
}

/* Plans Container */
.plans {
    display: flex;
    justify-content: center;
    width: 100%;
    margin-bottom: 15px;
}

/* Plan Box */
.plan {
    flex: 1;
    padding: 8px;
    margin: 0 10px;
    border: 2px solid #cccccc;
    border-radius: 10px;
    background: #f9f9f9;
    text-align: center;
    transition: all 0.3s ease;
}

.plan.selected {
    border-color: #0070f3;
    background: #e0f3ff;
}

.plan ul {
    list-style: none;
    padding: 0;
    color: #333333;
    text-align: left;
    margin-top: 5px;
}

.plan ul li {
    margin-bottom: 10px;
    padding-left: 10px;
    position: relative;
    font-size: 14px;
}

/* Total Price */
.total-price {
    margin-top: -18px;
    text-align: center;
    color: #403d3d;
    padding: 7px;
    border-radius: 10px;

}

.total-price h3 {
    font-size: 34px;
    margin: 0;
    color: #403d3d;
    font-family: -apple-system, "system-ui", "Segoe UI", sans-serif;
    font-weight: bold;
}

/*!* Strikethrough Price *!*/
/*.strikethrough {*/
/*    text-decoration: line-through;*/
/*    color: #ef2b2b;*/
/*    font-size: 20px;*/
/*    font-family: -apple-system, "system-ui", "Segoe UI", sans-serif;*/
/*    margin-right: 5px;*/
/*}*/

/* Button */
button {
    padding: 12px 35px;
    font-size: 16px;
    color: #ffffff;
    background-color: #0070f3;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    transition: background 0.3s ease;
    margin-top: 20px;
}

button:hover {
    background-color: #005bb5;
}

/* Responsive Design */
@media (max-width: 768px) {
    .plans {
        flex-direction: column;
        align-items: center;
    }

    .plan {
        width: 95%;
    }

    .plan-switch {
        width: 95%;
    }

    .paywall {
        width: 88%;
    }
}

.paywall-checkout-buttons{
    margin-top: -10px;
    width: 100%;
}

.first-paywall-benefit {
    margin-top: -5px;
    font-weight: bold;
}

.price-container {
    text-align: center;
    margin: 0px 0;
}

.price-row {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px; /* Space between MSRP and Final Price */
}

.strikethrough {
    text-decoration: line-through;
    color: #999;
    font-size: 1.3rem;
}

.final-price {
    font-size: 1.5rem;
    font-weight: bold;
    color: #333;
    margin: 0;
}

.you-save {
    background-color: #fff6d1; /* Light yellow background */
    color: #f59e0b; /* Bold orange color for savings */
    font-weight: bold;
    font-size: 1rem;
    padding: 3px 8px;
    border-radius: 4px;
    display: inline-block;
}

.you-save-row{
    margin-top: -10px;
}

