/* Base styles */
.tattoo-ai-comparison {
    font-family: 'Arial', sans-serif;
    padding: 20px;
    max-width: 1000px;
    margin: 70px auto;
    color: #f8f5f5;
    line-height: 1.6;
}

/* Header section */
.tattoo-ai-comparison__header {
    text-align: center;
    margin-bottom: 30px;
}

.tattoo-ai-comparison__header h1 {
    font-size: 2rem;
    color: #4358dc;
    margin-bottom: 10px;
}

.tattoo-ai-comparison__header p {
    font-size: 1.1rem;
    color: #f8f5f5;
}

/* Section styles */
.tattoo-ai-comparison__section {
    margin-bottom: 30px;
    text-align: center; /* Center the content in the section */
}

/* Benefits list */
.tattoo-ai-comparison__features {
    list-style: none;
    padding: 0;
    margin: 20px 0;
}

.tattoo-ai-comparison__features li {
    margin: 8px 0;
    font-size: 1rem;
    display: flex;
    align-items: center;
    flex-wrap: wrap; /* Allow wrapping of text */
}

.tattoo-ai-comparison__features li strong {
    margin-left: 8px;
}

/* Images and Videos */
.tattoo-ai-comparison__image {
    width: 100%;
    max-width: 600px;
    margin: 20px auto;
    display: block;
    border-radius: 8px;
    cursor: pointer;
}

.tattoo-ai-comparison__video {
    width: 100%;
    max-width: 800px;
    margin: 20px 0;
}

/* CTA Button */
.cta-button {
    display: inline-block;
    padding: 10px 20px;
    background-color: #ff6347;
    color: #fff;
    text-decoration: none;
    border-radius: 5px;
    font-weight: bold;
    transition: background-color 0.3s ease;
    text-align: center;
    margin: 0 auto; /* Center the button */
}

.cta-button:hover {
    background-color: #e0533b;
}

/* Modal */
.tattoo-ai-comparison__modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.tattoo-ai-comparison__modal-content {
    max-width: 90%;
    max-height: 90%;
    border-radius: 8px;
}

.tattoo-ai-comparison__close {
    position: absolute;
    top: 20px;
    right: 30px;
    font-size: 2rem;
    color: #fff;
    cursor: pointer;
}

/* Mobile adjustments */
@media (max-width: 768px) {
    .tattoo-ai-comparison__header h1 {
        font-size: 1.75rem;
    }

    .tattoo-ai-comparison__header p {
        font-size: 1rem;
    }

    .tattoo-ai-comparison__features li {
        font-size: 0.9rem; /* Smaller font for better fit */
    }

    .cta-button {
        width: auto; /* Change width to auto for centering */
        padding: 10px 20px;
    }

    .tattoo-ai-comparison__image {
        max-width: 100%;
    }

    .tattoo-ai-comparison__video {
        max-width: 100%;
    }
}
