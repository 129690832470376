/* Styles for the login prompt and sign-up button */
.login-prompt {
    text-align: center;
    padding: 5px;
    background-color: #f8f9fa;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.paypal-powered-by {
    display: none !important;
}

.checkout-buttons {
    width: 100%; /* Full container width */
    max-width: 500px; /* Adjust max-width as needed for button length */
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 2px; /* Spacing between buttons */
}

.checkout-buttons > div {
    width: 100%; /* Ensures buttons take up the full width of the container */
}




.login-prompt h5 {
    color: #333;
    margin-bottom: 5px;
}

.login-prompt p {
    color: #666;
    margin-bottom: 20px;
}

/* Modal Overlay and Modal Styles */
.modal-overlay {
    background: rgba(0, 0, 0, 0.5);
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 9999; /* Ensure it appears above other elements */
}

.checkout-modal {
    background: white;
    padding: 20px;
    width: 90%; /* Adjust the width of the modal */
    max-width: 500px; /* Adjust max-width as needed */
    margin: auto;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    z-index: 10000;
}

.checkout-model-title{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    text-align: center;
}

.checkout-button {
    margin-top: 13px;
    padding: 10px 20px;
    background-color: #4CAF50;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
    transition: background-color 0.3s ease;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    text-align: center;
}

.checkout-button:hover {
    background-color: #45a049;
}

.credit-card-button {
    padding: 10px 20px;
    background-color: #4CAF50;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
    margin-top: 10px;
}

.close-modal-button {
    margin-top: 20px;
    background: #f44336;
    color: white;
    padding: 7px 7px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.credit-card-section {
    border-radius: 8px;
    padding: 13px;
    margin: 20px auto; /* Center the box */
    width: auto; /* Allow the width to adjust based on content */
    max-width: 600px; /* Optional: Limit the maximum width */
    text-align: center; /* Center-align content for a cohesive look */
}

.checkout-buttons {
    margin-top: -20px; /* Adjust positioning to fit aesthetically */
    display: flex;
    flex-direction: column;
    align-items: center; /* Center the buttons within the box */
    gap: 10px; /* Add spacing between buttons */
    width: auto; /* Match the button's natural width */
}




.login-button {
    display: inline-block;
    padding: 10px 20px;
    color: #fff;
    background-color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    text-decoration: none;
    font-size: 16px;
    transition: background-color 0.3s ease;
    align-items: center;
}

.login-button:hover {
    background-color: #e0e0e3;
}

.isSandboxDesclimer{
    color: #757c83;
}

.checkout-login-container{
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 1.5rem;
}

.checkout-payment-container{
    display: flex;
    justify-content: center;

}
