/* General form styling */
.generate-form {
    width: 450px; /* Increased max width */
    margin: 0 auto;
    padding: 20px; /* Added more padding for a spacious feel */
    background-color: #282c34;
    border-radius: 10px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    color: #444;
}

.generate-form h3 {
    margin-bottom: 20px;
    text-align: center;
    color: #f8f9fa;
    font-size: 24px;
}

.form-row {
    margin-bottom: 1px;
    display: flex;
    flex-direction: column;
}

.form-row label {
    margin-top: 5px;
    font-size: 14px;
    color: #ddd;
}

.form-control {
    padding: 10px;
    border-radius: 5px;
    border: 1px solid #555;
    background-color: #444;
    color: white;
    appearance: none; /* Remove the default browser styles for dropdown */
    outline: none; /* Remove the blue outline on focus */
}

.form-control:focus, .form-control:active {
    background-color: #444; /* Ensures background stays the same on focus */
    border-color: #007bff; /* Optional: Change border color when focused */
    color: white; /* Keep text white */
}
.generate-button {
    width: 100%;
    padding: 10px; /* Slightly bigger button to match the wider form */
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

button:hover {
    background-color: #0056b3;
}

button:disabled {
    background-color: #999;
}

.error-text{
    color: red;
    font-size: 12px;
}

/* Mobile responsiveness */
@media (max-width: 600px) {
    .generate-form {
        width: 100%;
        padding: 15px; /* Adjusted padding for mobile */
        /*max-width: 100%;*/
    }

    .form-control {
        font-size: 14px;
    }

    html, body {
        max-width: 100%;
        overflow-x: hidden;
    }
}
