.generate-page {
    display: flex;
    justify-content: space-between;
    color: white;
    width: 100%;
    background-color: black;
    padding: 30px;
    margin-top: 65px;
    height: 90vh;
}

.generate-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 65%;
    background-color: black;
    padding: 5px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.results {
    width: 100%;
}

.image-grid {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    justify-content: center;
}

.result-item {
    flex: 1 1 calc(50% - 10px); /* Two images per row */
    max-width: calc(50% - 10px);
    box-sizing: border-box;
    cursor: pointer;
}

.result-item img {
    width: 100%;
    height: auto;
    border-radius: 8px;
}

.title-container {
    text-align: center;
    width: 100%;
}

.loading-generation-content {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    z-index: 9999;
}

.loading-generation-content h2 {
    margin-bottom: 30px;
}

.spinner-border {
    width: 6rem;
    height: 6rem;
}

.results img {
    max-width: 70%;
    height: auto;
    border-radius: 8px;
}

.results .share {
    display: flex;
    gap: 5px;
    margin-top: 5px;
}

.results .btn {
    padding: 5px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.results .btn-primary {
    background-color: #007bff;
    color: white;
}

.results .btn-info {
    background-color: #17a2b8;
    color: white;
}

.results .btn-success {
    background-color: #28a745;
    color: white;
}

.modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 80%;
    height: auto;
    background: rgba(0, 0, 0, 0.8);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
}

.modal-image {
    max-width: 90%;
    max-height: 90%;
    border-radius: 8px;
}

.modal-close {
    position: absolute;
    top: 20px;
    right: 20px;
    background: none;
    border: none;
    color: white;
    font-size: 2rem;
    cursor: pointer;
}

.generate-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    padding: 20px;
    border-radius: 8px;

}

.loading-generation-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    color: white;
}

.loading-generation-content h2, .loading-generation-content p {
    margin: 0;
    color: white;
}

.spinner-border {
    width: 4rem;
    height: 4rem;
    margin-top: 10px;
}

.results {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
}

.image-grid {
    display: flex;
    flex-wrap: wrap;
    gap: 5px;
    justify-content: center;
}

.result-item {
    flex: 1 1 calc(50% - 10px); /* Two images per row */
    max-width: calc(50% - 10px);
    box-sizing: border-box;
    margin-bottom: 5px;
}

.result-item img {
    width: 100%;
    height: auto;
    border-radius: 8px;
}

.generate-another-button {
    position: sticky;
    top: 10px;
    margin-top: 10px;
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    background-color: #ff8c00; /* Orange color */
    color: white;
    cursor: pointer;
    transition: background-color 0.3s ease;
    z-index: 1000; /* Ensure it appears above other content */
    font-size: 18px;
}

.generate-another-button:hover {
    background-color: #e07b00;
}

.share-text{
    font-size: 16px;
}

/* Mobile view */
@media (max-width: 600px) {
    html, body {
        max-width: 100%;
        overflow-x: hidden;
    }

    .result-item {
        flex: 1 1 100%; /* One image per row */
        max-width: 100%;
    }

    .results img {
        max-width: 95%;
    }

    .generate-page {
        display: flex;
        flex-direction: column;
        align-items: center;
        color: white;
        width: 100%;
        background-color: black;
        padding: 30px;
        margin-top: 60px;
        position: absolute;
    }
}

/*spinner*/

.progress-container {
    width: 170px;
    margin-top: 10px;
    text-align: center;
    font-size: 18px;
}

.progress-bar {
    width: 100%;
    height: 10px;
    background-color: #e0e0e0;
    border-radius: 5px;
    overflow: hidden;
}

.progress {
    height: 100%;
    background-color: #4caf50;
    transition: width 0.5s ease;
}


