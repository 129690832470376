
.cookie-consent-banner {
    position: fixed;
    bottom: 0;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.9);
    color: #fff;
    padding: 3px 12px;
    display: flex;
    justify-content: center; /* Center content horizontally */
    align-items: center;
    z-index: 1000;
    box-sizing: border-box;
    text-align: center;
}

.cookie-consent-banner p {
    font-size: 14px;
    margin: 0 20px 0 0;
    text-align: center;
}

.cookie-consent-banner a {
    color: #f39c12;
    text-decoration: underline;
}

.cookie-consent-button {
    background-color: #f39c12;
    color: #000;
    border: none;
    padding: 8px 16px;
    cursor: pointer;
    font-size: 14px;
    border-radius: 4px;
    margin-left: 10px;
    margin-top: 10px;
}

.cookie-consent-banner .close-button {
    background: none;
    border: none;
    color: #fff;
    font-size: 25px;
    cursor: pointer;
    position: absolute;
    top: -19px;
    right: 20px;
}

@media (max-width: 768px) {
    .cookie-consent-banner {
        flex-direction: column;
        align-items: center;
        text-align: center;
        padding: 4px 10px;
    }

    .cookie-consent-banner p {
        margin-bottom: 10px;
        margin-right: 0;
    }

    .cookie-consent-button {
        margin: -5px 0 0;
        font-size: 13px;
        width: 100%;
    }

    .cookie-consent-banner .close-button {
        position: absolute;
        top: -10px;
        right: 10px;
        font-size: 25px;
    }
}
