.spinner-container {
    height: 90vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: black;
    color: white;
    text-align: center;

}

.spinner-message {
    margin-top: 1rem;
}
