.footer {
    background-color: #1a1a1a;
    color: #fff;
    padding: 40px 20px;
    text-align: left;
}

.footer-container {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap; /* Allows flex items to wrap to the next line */
}

.footer-column {
    flex: 1; /* all column spreader all over the row */
    min-width: 200px; /* Ensures that columns have a minimum width */
    margin: 10px 20px;
}

.footer-logo-container {
    width: 230px;
    margin-bottom: 20px;
    font-size: 20px;
    color: gold;
}

.footer-column h3 {
    margin-bottom: 20px;
    font-size: 1.2rem;
}

.footer-column p {
    margin: 5px 0;
}

.footer-column a {
    color: #fff;
    text-decoration: none;
    margin: 5px 0;
}

.footer-column a:hover {
    text-decoration: underline;
}

.social-icons {
    display: flex;
    gap: 10px;
    margin-top: 20px;
}

.social-icons > * {
    font-size: 1.5rem;
    cursor: pointer;
}

.hr {
    border: 1px solid #444;
    margin: 20px 0;
}

.footer-bottom {
    text-align: center;
    margin-top: 20px;
    font-size: 0.9rem;
}
